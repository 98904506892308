let header = {
  language: [
    {
      title: "中国大陆(简体中文)",
      tag: "zh",
    },
    {
      title: "Global(English)",
      tag: "en",
    },
  ],
  menu: [
    {
      title: "首页",
      target: "/index",
      tag: "home",
      children: [],
    },
    {
      title: "产品中心",
      target: "",
      tag: "product",
      children: [],
    },
    {
      title: "企业业务",
      target: "",
      tag: "qiye",
      children: [
        {
          title: "业务概况",
          target: "/business",
        },
        {
          title: "核心业务",
          target: "/core",
        },
      ],
    },
    {
      title: "关于企顺",
      target: "",
      tag: "about",
      children: [
        {
          title: "关于企顺",
          target: "/about",
        },
        {
          title: "新闻中心",
          target: "/news",
        },
        {
          title: "社会责任",
          target: "/society",
        },
        {
          title: "CSR声明",
          target: "/csr",
        },
        {
          title: "联系我们",
          target: "/connect",
        },
      ],
    },
    {
      title: "员工关怀",
      target: "",
      tag: "yuangong",
      children: [
        {
          title: "员工关怀",
          target: "employee",
        },
        {
          title: "加入我们",
          target: "/joinus",
        },
      ],
    },
    {
      title: "服务与支持",
      target: "",
      tag: "service",
      children: [
        {
          title: "安全代码",
          target: "pages/serve-support",
        },
        {
          title: "服务热线",
          target: "/hotline",
        },
        {
          title: "常见问题",
          target: "/question",
        },
        {
          title: "下载中心",
          target: "/download",
        },
      ],
    },
  ],
};
export default header;
